/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { IErc20Auction, IErc20AuctionInterface } from "../IErc20Auction";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint24",
        name: "nftId",
        type: "uint24",
      },
      {
        internalType: "uint96",
        name: "biddedAmount",
        type: "uint96",
      },
    ],
    name: "createBid",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAuctionToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAuctionedToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getIdsToAuction",
    outputs: [
      {
        internalType: "uint24[]",
        name: "",
        type: "uint24[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
    ],
    name: "getMinPriceFor",
    outputs: [
      {
        internalType: "uint96",
        name: "",
        type: "uint96",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IErc20Auction__factory {
  static readonly abi = _abi;
  static createInterface(): IErc20AuctionInterface {
    return new Interface(_abi) as IErc20AuctionInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): IErc20Auction {
    return new Contract(address, _abi, runner) as unknown as IErc20Auction;
  }
}
