/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  IParallelAutoAuction,
  IParallelAutoAuctionInterface,
} from "../IParallelAutoAuction";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
      {
        indexed: false,
        internalType: "address",
        name: "bidder",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Bid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
      {
        indexed: false,
        internalType: "address",
        name: "bidder",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Won",
    type: "event",
  },
  {
    inputs: [],
    name: "auctionConfig",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "auctionedNft",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "lines",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "baseDuration",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "timeBuffer",
            type: "uint32",
          },
          {
            internalType: "uint96",
            name: "startingPrice",
            type: "uint96",
          },
          {
            internalType: "uint96",
            name: "bidIncrement",
            type: "uint96",
          },
        ],
        internalType: "struct AuctionConfig",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "nftId",
        type: "uint24",
      },
    ],
    name: "createBid",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAuctionedToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getIdsToAuction",
    outputs: [
      {
        internalType: "uint24[]",
        name: "",
        type: "uint24[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
    ],
    name: "getMinPriceFor",
    outputs: [
      {
        internalType: "uint96",
        name: "",
        type: "uint96",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
    ],
    name: "lineState",
    outputs: [
      {
        components: [
          {
            internalType: "uint24",
            name: "head",
            type: "uint24",
          },
          {
            internalType: "uint40",
            name: "startTime",
            type: "uint40",
          },
          {
            internalType: "uint40",
            name: "endTime",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "currentWinner",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "currentPrice",
            type: "uint96",
          },
        ],
        internalType: "struct LineState",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lineStates",
    outputs: [
      {
        components: [
          {
            internalType: "uint24",
            name: "head",
            type: "uint24",
          },
          {
            internalType: "uint40",
            name: "startTime",
            type: "uint40",
          },
          {
            internalType: "uint40",
            name: "endTime",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "currentWinner",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "currentPrice",
            type: "uint96",
          },
        ],
        internalType: "struct LineState[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "nftId",
        type: "uint24",
      },
    ],
    name: "settleAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class IParallelAutoAuction__factory {
  static readonly abi = _abi;
  static createInterface(): IParallelAutoAuctionInterface {
    return new Interface(_abi) as IParallelAutoAuctionInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): IParallelAutoAuction {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as IParallelAutoAuction;
  }
}
