/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  IHoldsParallelAutoAuctionData,
  IHoldsParallelAutoAuctionDataInterface,
} from "../IHoldsParallelAutoAuctionData";

const _abi = [
  {
    inputs: [],
    name: "auctionConfig",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "auctionedNft",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "lines",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "baseDuration",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "timeBuffer",
            type: "uint32",
          },
          {
            internalType: "uint96",
            name: "startingPrice",
            type: "uint96",
          },
          {
            internalType: "uint96",
            name: "bidIncrement",
            type: "uint96",
          },
        ],
        internalType: "struct AuctionConfig",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "tokenId",
        type: "uint24",
      },
    ],
    name: "lineState",
    outputs: [
      {
        components: [
          {
            internalType: "uint24",
            name: "head",
            type: "uint24",
          },
          {
            internalType: "uint40",
            name: "startTime",
            type: "uint40",
          },
          {
            internalType: "uint40",
            name: "endTime",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "currentWinner",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "currentPrice",
            type: "uint96",
          },
        ],
        internalType: "struct LineState",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IHoldsParallelAutoAuctionData__factory {
  static readonly abi = _abi;
  static createInterface(): IHoldsParallelAutoAuctionDataInterface {
    return new Interface(_abi) as IHoldsParallelAutoAuctionDataInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): IHoldsParallelAutoAuctionData {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as IHoldsParallelAutoAuctionData;
  }
}
