

export const EtherscanSvg = () => (
    <svg width="22" height="22" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_346)">
            <path
                d="M5.1572 11.898C5.15722 11.7655 5.18342 11.6343 5.23429 11.5119C5.28516 11.3895 5.3597 11.2784 5.45363 11.1849C5.54758 11.0915 5.65903 11.0174 5.78165 10.9672C5.90426 10.9169 6.0356 10.8913 6.16813 10.8919L7.84422 10.8974C8.11144 10.8974 8.36774 11.0036 8.55669 11.1925C8.74567 11.3815 8.85183 11.6378 8.85183 11.905V18.2426C9.04052 18.1866 9.28286 18.1269 9.54803 18.0644C9.73222 18.0212 9.89638 17.9169 10.0139 17.7686C10.1314 17.6203 10.1953 17.4367 10.1952 17.2474V9.38599C10.1952 9.11873 10.3014 8.86242 10.4903 8.67342C10.6793 8.48443 10.9356 8.37824 11.2029 8.37818H12.8823C13.1495 8.37824 13.4058 8.48443 13.5948 8.67342C13.7837 8.86242 13.8899 9.11873 13.8899 9.38599V16.6822C13.8899 16.6822 14.3104 16.512 14.7199 16.3392C14.8721 16.2748 15.0019 16.1671 15.0932 16.0294C15.1846 15.8918 15.2333 15.7303 15.2335 15.5651V6.86696C15.2335 6.59976 15.3396 6.34349 15.5285 6.15453C15.7175 5.96558 15.9737 5.85941 16.2409 5.85935H17.9203C18.1876 5.85935 18.4438 5.9655 18.6328 6.15448C18.8218 6.34343 18.9279 6.59972 18.9279 6.86696V14.0297C20.3839 12.9745 21.8594 11.7054 23.0304 10.1794C23.2003 9.95785 23.3127 9.69773 23.3576 9.42224C23.4025 9.14675 23.3785 8.86441 23.2877 8.60043C22.7457 7.04097 21.8842 5.61182 20.7582 4.40445C19.6321 3.19709 18.2664 2.23808 16.7485 1.5888C15.2306 0.939529 13.5938 0.614288 11.943 0.633893C10.2921 0.653498 8.66356 1.01752 7.16149 1.70266C5.6594 2.3878 4.31689 3.37896 3.21985 4.61274C2.12282 5.84649 1.29543 7.2957 0.790625 8.86761C0.285825 10.4395 0.114731 12.0995 0.288292 13.7413C0.461853 15.3831 0.976249 16.9706 1.79856 18.4022C1.94181 18.6491 2.15255 18.8501 2.40604 18.9814C2.65952 19.1128 2.94522 19.169 3.22959 19.1436C3.54725 19.1157 3.94276 19.0761 4.413 19.0209C4.61771 18.9977 4.8067 18.8999 4.94397 18.7463C5.08126 18.5927 5.15722 18.3939 5.15739 18.1879L5.1572 11.898Z"
                fill="#688292"
            />
            <path
                d="M5.12054 22.0629C6.89075 23.3507 8.98275 24.1238 11.1651 24.2965C13.3474 24.469 15.5349 24.0346 17.4856 23.0411C19.4363 22.0475 21.0741 20.5337 22.2179 18.6672C23.3615 16.8006 23.9666 14.6539 23.966 12.4648C23.966 12.1916 23.9533 11.9214 23.9352 11.6527C19.5997 18.1187 11.5949 21.1415 5.12092 22.0619"
                fill="#688292"
            />
        </g>
        <defs>
            <clipPath id="clip0_7_346">
                <rect width="24" height="24" fill="white" transform="translate(0.125 0.5)" />
            </clipPath>
        </defs>
    </svg>
)

